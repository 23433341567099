import { render, staticRenderFns } from "./SavedBeneficiaries.vue?vue&type=template&id=fa300bf6&scoped=true&"
import script from "./SavedBeneficiaries.vue?vue&type=script&lang=js&"
export * from "./SavedBeneficiaries.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fa300bf6",
  null
  
)

export default component.exports